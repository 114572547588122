import '~/templates/blog-post.scss';

import { graphql, Link } from 'gatsby';
import React from 'react';

import Button from '~/components/Button';
import Layout from '~/components/Layout';
import { BlogIndexQuery } from '~types/graphql-types';

interface BlogIndexPageProps {
  data: BlogIndexQuery;
}

/**
 * Renders an index page for blog posts
 */
export default function BlogIndexPage({ data }: BlogIndexPageProps): JSX.Element {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <div className="main container">
        <h1 className="pageTitle">Tenempleo</h1>
        <div className="content">
          {posts.map(({ node: post }) => (
            <div className="post post--withHover" key={post.frontmatter!.title!}>
              <h2 className="post-title">
                <Link className="post-link" to={post.fields!.slug!}>
                  {post.frontmatter!.title}
                </Link>
                <small className="post-date">{post.frontmatter!.date}</small>
              </h2>
              <div className="post-excerpt">{post.excerpt}</div>
              <div className="post-footer">
                <Button>
                  <Link to={post.fields!.slug!}>Leer más</Link>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogIndex {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
